
import React from 'react';
import Safe from './Safe';

export default function PageSection(props) {
  return props.config.map((section, index) => (
    <div key={`sec-${index}`} id={`sec-${index}`} className="section-indented">
      <p className="section-header pary-on-white page-section-header">{section.title}</p>
      {section.lists &&
        section.lists.map((listitem, listidx) => (
          <div key={`list-${listidx}`} className="mb-25">
            <strong><Safe type="p" content={listitem.title} /></strong>
            {listitem.text.length>=2 &&
              <ul>{listitem.text.map((parag, pidx) => <Safe key={`li-${pidx}`} type="li" content={parag} />)}</ul>
            }
            {listitem.text.length<2 && <p>{listitem.text[0]}</p>}
          </div>
        ))
      }
      {section.text && section.text.map((psection, psidx) => <Safe key={`ps-${psidx}`} type="p" content={psection} /> )}
    </div>
  ));
};
