
import React from 'react';
import { withPrefix, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/PageLayout';
import Safe from '../components/Safe';
import PageSection from '../components/PageSection';

export default function OrenitramPage(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.sitedata;
  const pagedata = props.data.pagedata;

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path} theme="page-bottom-pad">
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{pagedata.sitename}</title>
        <link rel="canonical" href={pagedata.siteurl} />
        <meta name="description" content={pagedata.metaDescription}></meta>
        <script src={withPrefix('optanonwrapper.js')} />
      </Helmet>
      <Safe className="section-indented pary-on-white" type="h1" content={pagedata.title} />
      <PageSection config={pagedata.sections} />
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleOrenitramQ($locale: String, $variation: String) {
    sitedata: siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      sitename
      siteurl
      title
      subtitle
      metaDescription
      startstudy
      fixedpanel {
        cta
        btntheme
      }
      navbar {
        theme
      }
      sections {
        id
        linkto
        title
      }
      footer {
        links {
          label
          to
        }
        text
        theme
      }
    }
    pagedata: pagesYaml(name: {eq: "orenitram"}) {
      name
      title
      metaDescription
      sections {
        text
        title
        lists {
          text
          title
        }
      }
      sitename
      siteurl
    }
  }`;
